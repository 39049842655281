import moment from "moment";
import { Booking, Colive, StaahPriceDetail } from "../../@types";
import { OverviewData } from "../../pages/colive/all/nOverviewAll";
import booking from "../../pages/colive/booking";
import calculateTotalRoom from "./calculateTotalRoom";


/*
_id:                string
name:               string
unitTypeName:       string
totalRoom:          number
dailyRoomBooked:    number
dailyRoomRate:      number
dailyRevenue:       number
monthlyRoomBooked:  number
monthlyRoomRate:    number
monthlyRevenue:     number
free:               number
renov:              number
emptyRoom:          number
totalRoomBooked:    number
avgRoomRate:        number
occRate:            number
revenue:            number
*/


export interface Rate {
  propertyId: string,
  unitTypeId: string
  rate: number
}


const transformBookingToOverviewData = async (propertyList: Colive[], bookings: Booking[], searchDate: string, rates: Rate[]): Promise<OverviewData> => {
  let total = {
    totalRoom: 0,
    totalDailyBooked: 0,
    totalDailyRevenue: 0,
    totalMonthlyBooked: 0,
    totalMonthlyRevenue: 0,
    totalFree: 0,
    totalRenov: 0,
    totalEmpty: 0,
    totalBooked: 0,
    averageRoomRate: 0,
    averageOccRate: 0,
    totalRevenue: 0,
  }
  let totalRtCount = 0
  const property = propertyList.map(colive => {
    const coliveBookings = bookings.filter(({ propertyId }) => propertyId === colive._id)
    console.log(`colive bookings ${colive.name} `, coliveBookings)
    return ({
      _id: colive._id,
      name: colive.name,
      roomTypes: colive.unitTypes.map(unitType => {
        let rtBookings = coliveBookings.filter(({ roomRequests }) => roomRequests[0]?.unitTypeId === unitType._id)
        console.log(`${colive.name} [${unitType.name}] ${rtBookings.length}`)

        let totalRoom = calculateTotalRoom(colive.units, unitType._id)

        const rtBookingsFree = rtBookings.filter(({ free }) => free === true)
        let rtFree = rtBookingsFree.length

        rtBookings = rtBookings.filter(({ free }) => free !== true)
        const rtBookingsRenov = rtBookings.filter(({ forcedClose }) => forcedClose === true)
        let rtRenov = rtBookingsRenov.length

        rtBookings = rtBookings.filter(({ forcedClose }) => forcedClose !== true)

        const rtDailyBookings = rtBookings.filter(({ term }) => term === 'DAILY')
        let totalDailyBookingRevenue = 0
        console.log(`THIS ${colive.name} ${unitType.name}`)
        rtDailyBookings.forEach(booking => {
          console.log('calculating totalDailyBookingRevenue')
          const totalCount = booking.roomRequests[0].count
          const totalDay = moment(booking.roomRequests[0].checkOutDate).diff(moment(booking.roomRequests[0].checkInDate), 'days')
          const staahPrice = (booking.origin === 'STAAH' && totalDay > 1) ? Number((booking.roomRequests[0]?.price as StaahPriceDetail[])?.find(({ date }) => date === searchDate)?.amount) * totalCount : undefined

          console.log(`staahPrice: ${staahPrice}`)
          console.log(Number(staahPrice))
          console.log('booking price: ', booking?.price)
          console.log('totalDay: ' + totalDay)
          totalDailyBookingRevenue += (booking.origin === 'STAAH' && totalDay > 1 && !isNaN(Number(staahPrice))) ? Number(staahPrice) : (booking.price / totalDay)
          console.log(`totalDailyBookingRevenue: ${totalDailyBookingRevenue}`)
          if (isNaN(totalDailyBookingRevenue)) console.log("HERHERHEHREHrHERHEHrEH")
        });
        let totalDailyRoomBooked = 0
        rtDailyBookings.forEach(({ roomRequests }) => {
          totalDailyRoomBooked += roomRequests[0].count
        })
        console.log(totalDailyRoomBooked)
        console.log(totalDailyBookingRevenue)
        let dailyRoomRate = totalDailyBookingRevenue / totalDailyRoomBooked
        dailyRoomRate = isNaN(dailyRoomRate) ? 0 : dailyRoomRate

        const rtMonthlyBookings = rtBookings.filter(({ term }) => term === 'MONTHLY')
        let totalMonthlyBookingRevenue = 0
        console.log("Monthly Bookings ", rtMonthlyBookings)
        rtMonthlyBookings.forEach(booking => {
          const totalDay = moment(booking.roomRequests[0].checkOutDate).diff(moment(booking.roomRequests[0].checkInDate), 'days')
          totalMonthlyBookingRevenue += booking.price / totalDay
        });
        let totalMonthlyRoomBooked = rtMonthlyBookings.length
        let monthlyRoomRate = totalMonthlyBookingRevenue / totalMonthlyRoomBooked
        monthlyRoomRate = isNaN(monthlyRoomRate) ? 0 : monthlyRoomRate

        let avgRoomRate = (totalDailyBookingRevenue + totalMonthlyBookingRevenue) / (totalDailyRoomBooked + totalMonthlyRoomBooked)
        avgRoomRate = isNaN(avgRoomRate) ? 0 : avgRoomRate

        let emptyRoom = totalRoom - totalDailyRoomBooked - totalMonthlyRoomBooked - rtFree - rtRenov
        let occRate = (totalDailyRoomBooked + totalMonthlyRoomBooked + rtFree + rtRenov) / totalRoom
        total.totalRoom += totalRoom
        total.totalDailyBooked += totalDailyRoomBooked
        total.totalDailyRevenue += totalDailyBookingRevenue
        total.totalMonthlyBooked += totalMonthlyRoomBooked
        total.totalMonthlyRevenue += totalMonthlyBookingRevenue
        total.totalFree += rtFree
        total.totalRenov += rtRenov
        total.totalEmpty += emptyRoom
        total.totalBooked += (totalDailyRoomBooked + totalMonthlyRoomBooked)
        total.averageRoomRate += avgRoomRate
        total.averageOccRate += occRate
        total.totalRevenue += totalDailyBookingRevenue + totalMonthlyBookingRevenue
        totalRtCount++
        return ({
          _id: unitType._id,
          name: colive.name,
          unitTypeName: unitType.name,
          totalRoom: totalRoom,
          dailyRoomBooked: totalDailyRoomBooked,
          dailyRoomRate: dailyRoomRate,
          dailyRevenue: totalDailyBookingRevenue,
          monthlyRoomBooked: totalMonthlyRoomBooked,
          monthlyRoomRate: monthlyRoomRate,
          monthlyRevenue: totalMonthlyBookingRevenue,
          free: rtFree,
          renov: rtRenov,
          emptyRoom: emptyRoom,
          totalRoomBooked: totalDailyRoomBooked + totalMonthlyRoomBooked,
          avgRoomRate: avgRoomRate,
          occRate: occRate,
          revenue: totalDailyBookingRevenue + totalMonthlyBookingRevenue,

          rate: rates.find(({ propertyId, unitTypeId }) => ((propertyId === colive._id) && (unitType._id === unitTypeId)))?.rate || 0
        })
      })
    })
  })
  total.averageOccRate = total.averageOccRate / totalRtCount
  total.averageRoomRate = total.totalRevenue / total.totalBooked
  return ({
    property: property,
    total: total
  })
}

export default transformBookingToOverviewData
