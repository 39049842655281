import React, { Component, useEffect, useState } from 'react'
import { TableViewButton } from '../../../components/TableViewButton'
import { DownloadButton } from '../../../components/DownloadButton'
import { AxiosError } from 'axios'

import API from '../../../api'
import moment, { Moment } from 'moment'
import formatRupiah from '../../../utility/rupiahFormator'
import { OverviewDailyTable, OverviewGroup, OverviewMonthlyTable, OverviewSection, OverviewTableView, TermPicker } from '../../../components'
import { OverviewRoomTypeData, TotalOverviewData } from '../../../components/OverviewTableView'
import { transformBookingToOverviewData, transformOverviewDataToOverviewGroupData, transformOverviewDataToTableViewData } from '../../../v2'
import { Rate } from '../../../v2/functions/transformBookingToOverview'
import Excel from 'exceljs'
import { saveAs } from 'file-saver';
import { ViewTerm } from '../../../@types'

export interface OverviewData {
  property: {
    _id: string,
    name: string,
    roomTypes: OverviewRoomTypeData[]
  }[]
  total: TotalOverviewData
}

const labels = ['Hotel', 'Type', 'Total Room', 'Daily Room Booked', 'Daily Room Rate', 'Daily Revenue', 'Monthly Room Booked', 'Monthly Room Rate', 'Monthly Revenue', 'Free', 'Renov', 'Empty Room', 'Total Room Booked', 'Average Room Rate', 'Occ. Rate', 'Total Revenue']

const NOverviewAll = ({ propertyList }: any) => {
  const [selectedDate, setSelectedDate] = useState<Moment>(moment())
  const [tableView, setTableView] = useState<boolean>(false)
  const [overviewData, setOverviewData] = useState<OverviewData | undefined>()
  // const [termMode, setTermMode] = useState<ViewTerm>('DAILY')
  const [loading, setLoading] = useState<boolean>(false)

  const downloadOCCrate = async () => {
    if (overviewData === undefined) return null
    const workbook = new Excel.Workbook()
    workbook.creator = 'Me'
    workbook.lastModifiedBy = 'Her'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()
    const worksheet = workbook.addWorksheet('My Sheet')
    let rowIndex = 1
    let row = worksheet.getRow(rowIndex)
    let cell = row.getCell(1)
    cell.value = moment(selectedDate, 'YYYY-MM-DD').format('dddd, DD-MMM-YYYY')
    rowIndex++

    labels.map((myLabel, index) => {
      row = worksheet.getRow(rowIndex)
      cell = row.getCell(index + 1)
      cell.value = myLabel
    })
    rowIndex++

    transformOverviewDataToTableViewData(overviewData).roomTypes.map(({ name, unitTypeName, dailyRoomBooked, dailyRoomRate, dailyRevenue, monthlyRoomBooked, monthlyRoomRate, monthlyRevenue, free, renov, emptyRoom, totalRoomBooked, avgRoomRate, occRate, revenue, totalRoom }) => {
      [name, unitTypeName, totalRoom, dailyRoomBooked, formatRupiah(Math.floor(dailyRoomRate)), formatRupiah(Math.floor(dailyRevenue)), monthlyRoomBooked, formatRupiah(Math.floor(monthlyRoomRate)), formatRupiah(Math.floor(monthlyRevenue)), free, renov, emptyRoom, totalRoomBooked, formatRupiah(Math.floor(avgRoomRate)), Math.floor(occRate * 100).toString() + '%', formatRupiah(Math.floor(revenue))].map((value, index) => {
        row = worksheet.getRow(rowIndex)
        cell = row.getCell(index + 1)
        cell.value = value
      })
      rowIndex++
    })

    const {
      totalRoom,
      totalDailyBooked,
      totalDailyRevenue,
      totalMonthlyBooked,
      totalMonthlyRevenue,
      totalFree,
      totalRenov,
      totalEmpty,
      totalBooked,
      averageRoomRate,
      averageOccRate,
      totalRevenue
    } = overviewData.total

    const bufferArray = ['TOTAL atau AVERAGE', '', totalRoom, totalDailyBooked, '', formatRupiah(Math.floor(totalDailyRevenue)), totalMonthlyBooked, '', formatRupiah(Math.floor(totalMonthlyRevenue)), totalFree, totalRenov, totalEmpty, totalBooked, formatRupiah(Math.floor(averageRoomRate)), Math.floor((averageOccRate * 100)).toString() + '%', formatRupiah(Math.floor(totalRevenue))]
    bufferArray.map((value, index) => {
      row = worksheet.getRow(rowIndex)
      cell = row.getCell(index + 1)
      cell.value = value
    })
    rowIndex++

    // write to a new buffer
    workbook.xlsx.writeBuffer().then((data) => {
      var blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      saveAs(blob, "pms.xlsx")
    });
  }
  const loadData = async () => {
    try {
      if (propertyList !== undefined && propertyList.length !== 0) {
        console.log(propertyList)
        let data: any[] = []
        const startDate = selectedDate.clone().format('YYYY-MM-DD')
        const endDate = startDate
        setLoading(true)
        setOverviewData(undefined)
        let promises = []
        for (let i = 0; i < propertyList.length - 1; i++) {
          // const result = await API.colive.booking.all({ propertyId: propertyList[i]._id, startDate: startDate, endDate: endDate })
          // data.push(result.data)
          promises.push(API.colive.booking.all({ propertyId: propertyList[i]._id, startDate: startDate, endDate: endDate }))
        }
        const results = await Promise.all(promises)
        results.forEach((result: any) => {
          // if (result?.error !== false) {
          if (result instanceof Error && (result as AxiosError)?.response?.status === 404) {
            // console.log('isError')
            // console.log((result as AxiosError).response)
            data.push([])
          } else data.push(result.data)
        })

        let promises2 = []
        let rates: Rate[] = []
        for (let i = 0; i < propertyList.length - 1; i++) {
          for (let j = 0; j < propertyList[i].unitTypes.length; j++) {
            promises2.push(API.colive.nrates.get({
              propertyId: propertyList[i]._id,
              unitTypeId: propertyList[i].unitTypes[j]._id,
              startDate: startDate,
              endDate: endDate,
              monthly: false
            }))

            // console.log(`MY RATES ${propertyList[i].name} ${propertyList[i].unitTypes[j].name} ${JSON.stringify(result.data)}`)
            // const price = result?.data[0]?.rates[0]?.price
            // rates.push({
            //   propertyId: propertyList[i]._id,
            //   unitTypeId: propertyList[i].unitTypes[j]._id,
            //   rate: price === undefined ? 0 : price
            // })
          }
        }
        const results2 = await Promise.all(promises2)
        let indexCount = 0
        for (let i = 0; i < propertyList.length - 1; i++) {
          for (let j = 0; j < propertyList[i].unitTypes.length; j++) {
            const result = results2[indexCount]
            console.log(`MY RATES ${propertyList[i].name} ${propertyList[i].unitTypes[j].name} ${JSON.stringify(result.data)}`)
            const price = result?.data[0]?.rates[0]?.price
            rates.push({
              propertyId: propertyList[i]._id,
              unitTypeId: propertyList[i].unitTypes[j]._id,
              rate: price === undefined ? 0 : price
            })
            indexCount++
          }
        }

        const overviewDataBuffer = await transformBookingToOverviewData(propertyList.filter(({ _id }: any) => _id !== 'ALL'), data.flat(), startDate, rates)
        console.log("OVERVIEW DATA BVUFFER")
        console.log(overviewDataBuffer)
        setLoading(false)
        setOverviewData(overviewDataBuffer)
      } else {
        console.log('property list empty')
      }
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  // const onChangeTerm = (param: ViewTerm) => {
  //   setTermMode(param)
  // }
  useEffect(() => {
    loadData()
  }, [selectedDate])
  return (
    <div className={'Overview TabPage'} style={{ overflow: 'hidden' }}>
      <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '100%' }}>
        <div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 8, paddingBottom: 12, display: 'flex', flexDirection: 'row', overflowX: 'auto' }}>
          <div
            className={'Neu'}
            style={{ width: 'min-content', minWidth: 125.33, height: 40, borderRadius: 5, overflow: 'hidden' }}>
            <input
              style={{ height: '100%' }}
              value={selectedDate.format('YYYY-MM-DD')}
              type={'date'}
              onChange={(e) => setSelectedDate(moment(e.target.value, 'YYYY-MM-DD'))} />
          </div>
          <TableViewButton style={{ marginLeft: 16 }} active={tableView} onClick={() => { setTableView(!tableView) }} />
          {/* <TermPicker term={termMode} onChangeTerm={onChangeTerm}/> */}
          {tableView ? <DownloadButton onClick={downloadOCCrate} style={{ marginLeft: 16 }} /> : null}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxWidth: '100vw', paddingTop: 12, overflowX: 'auto' }}>
          {/* {
          (loading === false && overviewData !== undefined) ?
            (
              tableView ? 
                termMode === 'DAILY' ?
                  <OverviewDailyTable     data={transformOverviewDataToTableViewData(overviewData)}/> :
                  termMode === 'MONTHLY' ? 
                    <OverviewMonthlyTable data={transformOverviewDataToTableViewData(overviewData)}/> :
                    <OverviewTableView    data={transformOverviewDataToTableViewData(overviewData)}/> : 
                <OverviewGroup data={transformOverviewDataToOverviewGroupData(overviewData)}/>
            ) :
            undefined
        } */}
          {
            (loading === false && overviewData !== undefined) ?
              (
                tableView ?
                  <OverviewTableView data={transformOverviewDataToTableViewData(overviewData)} /> :
                  <OverviewGroup data={transformOverviewDataToOverviewGroupData(overviewData)} />
              ) :
              undefined
          }
        </div>
      </div>
    </div>
  )
}

export default NOverviewAll
